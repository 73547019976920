import '../App.css';
import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AlertCard from "./AlertCard"
import Utils from "../Utils"
import PropTypes from 'prop-types';
import { properties } from "../TableConfig"

function transformSummaryToTable(summary) {
    if (summary.length <= 0) return []
    let defaultColor = "black";
    let level1 = Object.keys(summary).map((row) => {
        if (row !== "stats" && row !== "alerts" && row !== "assets") {
            let props = properties.parameters[row];
            return { key: row, description: props && props.description ? props.description : row, value: Utils.transformValue(summary[row], props ? props.format : null), color: defaultColor }
        }
        return null;
    })
    return level1.filter(n => n);
}

function transformSummaryToYears(summary) {
    if (summary.length <= 0) return []
    let defaultColor = "black";

    let level1 = Object.keys(summary.stats).map((row) => {
        if (!(summary.stats[row] instanceof Object)) {
            let props = properties.parameters[row];
            return { key: row, description: props && props.description ? props.description : row, value: Utils.transformValue(summary.stats[row], props ? props.format : null), color: (props && props.format && (props.format === "MONEY" || props.format === "PERCENT")) ? (summary.stats[row] > 0 ? "green" : summary.stats[row] < 0 ? "red" : defaultColor) : defaultColor }
        }
        return null
    })
    let result = []
    result.push({ name: "current", values: sortIt(level1.filter(value => value)) })
    for (const key in summary.stats) {
        if (summary.stats[key] instanceof Object) {
            let item = summary.stats[key]
            let level2 = Object.keys(item).map((row) => {
                if (!(item[row] instanceof Object)) {
                    let valueT = item[row]
                    let props = properties.parameters[row];
                    return { key: row, description: props && props.description ? props.description : row, value: Utils.transformValue(valueT, props ? props.format : null), color: (props && props.format && (props.format === "MONEY" || props.format === "PERCENT")) ? (valueT > 0 ? "green" : valueT < 0 ? "red" : defaultColor) : defaultColor }
                }
                return null
            })
            result.push({ name: key, values: sortIt(level2.filter(value => value)) })
        }
    }
    return result.sort((a, b) => 
        a.name === 'current' ? -1 : b.name === 'current' ? 1 : b.name.localeCompare(a.name)
      );
}

function sortIt(level2) {
    let paramOrder = Object.keys(properties.parameters)
    level2.sort((function (a, b) {
        let aIndex = paramOrder.indexOf(a.key)
        if (aIndex < 0) aIndex = 999
        if (a.key.indexOf("_") >= 0) {
            aIndex = paramOrder.indexOf(a.key.substring(0, a.key.indexOf("_") + 1))
            if (aIndex < 0) aIndex = 950
        }
        let bIndex = paramOrder.indexOf(b.key)
        if (bIndex < 0) bIndex = 999
        if (b.key.indexOf("_") >= 0) {
            bIndex = paramOrder.indexOf(b.key.substring(0, b.key.indexOf("_") + 1))
            if (bIndex < 0) bIndex = 950
        }
        return aIndex - bIndex;
    }))
    return level2
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function GeneralInfo({ data }) {
    const [summaryObject, setSummaryObject] = useState([]);
    const [summaryTable, setSummaryTable] = useState([]);
    const [summaryYears, setSummaryYears] = useState([]);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setSummaryObject(data);
        setSummaryTable(transformSummaryToTable(data))
        setSummaryYears(transformSummaryToYears(data))
    }, [data]);

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };

    return (
        <Container key={"container01"}>
            <Stack spacing={2}>
                {summaryObject.stats &&
                    <Typography sx={{ fontWeight: 'bold' }}>Updated: {Utils.transformValue(summaryObject.timestamp, "TIMESTAMP")}</Typography>
                }
                <Stack spacing={3}>
                    {summaryObject.alerts && summaryObject.alerts.map((row) => (
                        <AlertCard key={row.isin} alert={row} />
                    ))}
                </Stack>
                {summaryObject.stats &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {summaryTable.map((row) => (
                                    <TableRow
                                        key={row.key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component="th" scope="row"><Typography>{row.description}</Typography></TableCell>
                                        <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }} color={row.color}>{row.value}</Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                <Box sx={{ width: '100%' }}>


                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Years">
                            {summaryYears.map((year) => (
                                <Tab label={year.name} {...a11yProps(0)} />
                            ))}
                        </Tabs>
                    </Box>
                    {summaryYears.map((year, index) => (
                        <CustomTabPanel value={value} index={index}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {year.values.map((row) => (
                                            <TableRow
                                                key={row.key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell component="th" scope="row"><Typography>{row.description}</Typography></TableCell>
                                                <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }} color={row.color}>{row.value}</Typography></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomTabPanel>
                    ))}
                </Box>

            </Stack>
        </Container>
    );
}

export default GeneralInfo;
