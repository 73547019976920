import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import SecureApp from './SecureApp';

function App() {
  return (
    <div className="App" key="mainAppDiv">
        <Authenticator key="authenticator01">
          {({ signOut, user }) => (
            <SecureApp key="secureApp01" signOut={signOut} user={user} />
          )}
        </Authenticator>
    </div>
  );
}

export default App;
