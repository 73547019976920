function transformValue(value, format) {
    if (format === "MONEY") {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    }
    if (format === "PERCENT") {
        return value.toFixed(2) + " %"
    }
    if (format === "TIMESTAMP") {
        return new Intl.DateTimeFormat('en', { dateStyle: 'full', timeStyle: 'medium' }).format(new Date(value));
    }
    return value;
}

function prepareForNotifications() {
    return askPermission().then(() => {
        navigator.serviceWorker
            .register('/sw.js')
            .then(function (registration) {
                console.log('Service worker successfully registered.');
                return registration;
            })
            .catch(function (err) {
                console.error('Unable to register service worker.', err);
            });
    })
}

function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });
        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
            throw new Error("We weren't granted permission.");
        }
    });
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function subscribeUserToPush() {
    return navigator.serviceWorker
        .register('/sw.js')
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    'BJdzBQnr8byVpRT_YEFP6dRd1AWgQYz2cpypGS6XRxvHdHaS77qiMayLlnQE6DhNO2HocqqwYhTWZO2BPZgCss0',
                ),
            };
            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
            return pushSubscription;
        });
}

module.exports = {
    prepareForNotifications,
    subscribeUserToPush,
    transformValue
}