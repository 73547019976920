import '../App.css';
import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { CollapsibleRow, createData } from "./CollapsibleRow"
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

//using javascript, I have a list of assets, in every asset there are a list of values, every item contains a amount, quantity and a date, please write a funtion to order the list of assets with the following criteria: first the assets with the average older dates

function orderAssetsByTaxes(assets) {
  return assets.sort((assetA, assetB) => {
    const valueA = calculateTaxes(assetA.values);
    const valueB = calculateTaxes(assetB.values);

    return valueB - valueA;
  });

  function calculateTaxes(values) {
    const potentialTaxes = values.reduce((sum, item) => {
      return sum + item.potentialTaxes;
    }, 0);

    return potentialTaxes;
  }
}

function orderAssetsByEarnings(assets) {
  return assets.sort((assetA, assetB) => {
    const valueA = calculateEarnings(assetA.values);
    const valueB = calculateEarnings(assetB.values);

    return valueB - valueA;
  });

  function calculateEarnings(values) {
    const potentialEarnings = values.reduce((sum, item) => {
      return sum + item.potentialEarnings;
    }, 0);

    return potentialEarnings;
  }
}

function orderAssetsByOldestItemDate(assets) {
  return assets.sort((assetA, assetB) => {
    const oldestDateA = assetA.values.reduce((oldest, current) => {
      return new Date(oldest.date) < new Date(current.date) ? oldest : current;
    }).date;

    const oldestDateB = assetB.values.reduce((oldest, current) => {
      return new Date(oldest.date) < new Date(current.date) ? oldest : current;
    }).date;

    return new Date(oldestDateA) - new Date(oldestDateB);
  });
}

function orderAssetsByAverageItemDate(assets) {
  return assets.sort((assetA, assetB) => {
    const averageDateA = calculateAverageDate(assetA.values);
    const averageDateB = calculateAverageDate(assetB.values);
    return averageDateA - averageDateB;
  });

  function calculateAverageDate(values) {
    const totalMilliseconds = values.reduce((sum, item) => {
      return sum + new Date(item.date).getTime();
    }, 0);

    return totalMilliseconds / values.length;
  }
}

function transformSummaryToAssetsRows(summary) {
  let result = []
  if (!summary.assets) return result
  summary.assets.forEach(asset => {
    result.push(createData(asset.id, asset.name, asset.quantity, asset.type, asset.profit, asset.currentPricePerUnit, asset.values))
  });
  return orderAssetsByAverageItemDate(result)
}

export function CurrentStocks({ data }) {
  const [assetsRows, setAssetsRows] = useState([]);
  const [order, setOrder] = React.useState('old');
  const [allOpen, setAllOpen] = useState(false);

  useEffect(() => {
    setAssetsRows(transformSummaryToAssetsRows(data))
  }, [data]);

  const handleChange2 = (event, newOrder) => {
    if (newOrder === "open") setAllOpen(true)
    if (newOrder === "close") setAllOpen(false)
  };

  const handleChange = (event, newOrder) => {
    if (newOrder === "old") orderAssetsByOldestItemDate(assetsRows)
    if (newOrder === "avrg") orderAssetsByAverageItemDate(assetsRows)
    if (newOrder === "earning") orderAssetsByEarnings(assetsRows)
    if (newOrder === "taxes") orderAssetsByTaxes(assetsRows)

    setOrder(newOrder);
  };

  const children2 = [
    <ToggleButton value="open" key="open">
      <FormatAlignLeftIcon /> Open
    </ToggleButton>,
    <ToggleButton value="close" key="close">
      <FormatAlignCenterIcon /> Close
    </ToggleButton>
  ];

  const children = [
    <ToggleButton value="old" key="old">
      <FormatAlignLeftIcon /> By Oldest Item Date
    </ToggleButton>,
    <ToggleButton value="avrg" key="avrg">
      <FormatAlignCenterIcon /> By Average Item Date
    </ToggleButton>,
    <ToggleButton value="earning" key="earning">
      <FormatAlignRightIcon /> By Potential Earning
    </ToggleButton>,
    <ToggleButton value="taxes" key="taxes">
      <FormatAlignJustifyIcon />By Potential Taxes
    </ToggleButton>,
  ];

  const control = {
    value: order,
    onChange: handleChange,
    exclusive: true,
  };

  const control2 = {
    value: order,
    onChange: handleChange2,
    exclusive: true,
  };

  return (
    <Container key={"container01"} maxWidth="md">
      <ToggleButtonGroup size="small" {...control2} aria-label="Small sizes">
        {children2}
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {children}
      </ToggleButtonGroup>
      {assetsRows &&
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 80 }} aria-label="simple table">
            <TableBody>
              {assetsRows.map((row) => (
                <CollapsibleRow key={row.name} row={row} open={allOpen} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
    </Container>
  );
}

export default CurrentStocks;
