import './App.css';
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { JSONTree } from 'react-json-tree';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Box, Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { GeneralInfo } from "./components/GeneralInfo"
import { CurrentStocks } from "./components/CurrentStocks"
import PropTypes from 'prop-types';
import Utils from "./Utils"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function SecureApp({ signOut, user }) {
  const [summaryObject, setSummaryObject] = useState([]);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    Auth.currentSession().then(response => {
      return response.getIdToken().getJwtToken()
    }).then(tokenP => {
      Utils.prepareForNotifications().then(() => {
        Utils.subscribeUserToPush().then((data) => {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + tokenP },
            body: JSON.stringify(data)
          };
          fetch('https://kg9taggvu2.execute-api.eu-central-1.amazonaws.com/dev/user-push-data', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
        })
      })
      return fetch('https://kg9taggvu2.execute-api.eu-central-1.amazonaws.com/dev/summary', {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + tokenP
        }
      })
    }).then((response) => response.json())
      .then((data) => {
        setSummaryObject(data);
      }).catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <CssBaseline />


      <Container key={"container01"} maxWidth="md">
        <Stack spacing={2}>
          <Typography variant="h5" padding={5} >Welcome {user.username}</Typography>
          {!summaryObject.stats && (
            <Stack spacing={1}>
              <Skeleton key="skeleton01" variant="rounded" height={100} />
              <Skeleton key="skeleton02" variant="rounded" height={100} />
              <Skeleton key="skeleton03" variant="rounded" height={300} />
              <Skeleton key="skeleton04" variant="rounded" height={60} />
            </Stack>
          )}
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="Finances tabs">
                <Tab label="General Info" {...a11yProps(0)} />
                <Tab label="Current Stocks" {...a11yProps(1)} />
                <Tab label="Raw data" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <GeneralInfo data={summaryObject}></GeneralInfo>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CurrentStocks data={summaryObject}></CurrentStocks>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {summaryObject.stats && <JSONTree shouldExpandNodeInitially={() => false} shouldExpandNode={() => false} hideRoot={() => true} data={{ expandToSee: summaryObject }} />}
            </CustomTabPanel>
          </Box>
          <Divider />
          <Button onClick={signOut} variant="outlined">Sign out</Button>
          <Divider />
        </Stack>
      </Container>
    </React.Fragment>
  );
}

export default SecureApp;
