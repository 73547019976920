
import { Chip, Divider, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Utils from "../Utils"

function AlertCard({ alert }) {
    return (
        <Card sx={{ minWidth: 100 }}>
            <CardContent>
                <Box m={2} pt={3}>
                    <Chip label={alert.type} style={{ backgroundColor: alert.type === "SELL_OPPORTUNITY" ? "red" : "green" }} variant="outlined" />
                </Box>
                    <Divider variant="middle" />
                <Box m={2} pt={0}>
                <Stack>
                    <Typography sx={{ mb: 1.5 }}>
                        {alert.isin} - {alert.name}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} >
                        Current Price {Utils.transformValue(alert.marketCurrentPrce, "MONEY")} - Performance 1D {Utils.transformValue(alert.marketCurrentPerformace1D, "PERCENT")}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} >
                        userSharesQuantity {alert.userSharesQuantity} - userPriceByShare {Utils.transformValue(alert.userPriceByShare, "MONEY")}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} >
                        userTotalPaid {Utils.transformValue(alert.userTotalPaid, "MONEY")}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} >
                        profitValue {Utils.transformValue(alert.profitValue, "MONEY")} - profitPercentage {Utils.transformValue(alert.profitPercentage, "PERCENT")}
                    </Typography>
                </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}
export default AlertCard;