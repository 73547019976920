import '../App.css';
import React, { useEffect, useState } from "react";
import { IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';

export function CollapsibleRow(props) {
    const { row, open } = props;
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
      setIsOpen(open);
    }, [open]);

    function getTaxColor(value) {
      if (value.potentialTaxes) {
        if (value.potentialTaxes > 200) return "red"
        if (value.potentialTaxes > 100) return "orange"
      }
      if (value.totalPotentialTaxes) {
        if (value.totalPotentialTaxes > 500) return "red"
        if (value.totalPotentialTaxes > 200) return "orange"
        if (value.totalPotentialTaxes < 0) return "gray"
      }
      return "green"
    }

  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.quantity}</TableCell>
          <TableCell align="right">{row.type}</TableCell>
          <TableCell align="right">Sold Profit:{Math.round(row.profit).toFixed(2)}</TableCell>
          <TableCell align="right">Current Value:{Math.round(row.currentPricePerUnit).toFixed(2)}</TableCell>          
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                {row.name} - Current Assets
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell align="right">Average price</TableCell>
                      <TableCell align="right">Total price</TableCell>
                      <TableCell align="right">Potential Earning</TableCell>
                      <TableCell align="right">Potential Taxes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.values.map((valuesRow) => (
                      <TableRow key={valuesRow.date}>
                        <TableCell component="th" scope="row">
                          { new Date(valuesRow.date).toLocaleDateString('es-ES') }
                        </TableCell>
                        <TableCell>{valuesRow.quantity}</TableCell>
                        <TableCell align="right">{valuesRow.averagePrice}</TableCell>
                        <TableCell align="right">
                          {Math.round(valuesRow.averagePrice * valuesRow.quantity).toFixed(2) }€
                        </TableCell>
                        <TableCell align="right">{Math.round(valuesRow.potentialEarnings).toFixed(2)}€</TableCell>
                        <TableCell align="right"> <Typography sx={{ fontSize: "0.9rem" }} color={getTaxColor(valuesRow)}>{Math.round(valuesRow.potentialTaxes).toFixed(2)}€</Typography></TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={row.name+"total"}>
                        <TableCell component="th" scope="row">
                        <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">{Math.round(row.totalPotentialEarnings).toFixed(2)}€</TableCell>
                        <TableCell align="right"> <Typography sx={{ fontWeight: 'bold', fontSize: "0.9rem" }} color={getTaxColor(row)}>{Math.round(row.totalPotentialTaxes).toFixed(2)}€</Typography></TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  export function createData(id, name, quantity, type, profit, currentPricePerUnit, values) {
    const totalPotentialEarnings = values.reduce((acumulador, value) => { return acumulador + value.potentialEarnings;}, 0); 
    const totalPotentialTaxes = values.reduce((acumulador, value) => { return acumulador + value.potentialTaxes;}, 0); 
    return {    id,    name,    quantity,    type,    profit,    currentPricePerUnit, totalPotentialEarnings, totalPotentialTaxes, values  };
  }
  
  export default CollapsibleRow;