
export const properties = {
    parameters: {
        transactionsCount_: {
            description: "Transactions in ",
            format: "NUMBER"
        },
        transactionsCount: {
            description: "Transactions Count",
            format: "NUMBER"
        },
        assetsValuePlusAvailable: {
            description: "Assets Value Plus Available",
            format: "MONEY"
        },
        earnings_: {
            description: "Earnings",
            format: "MONEY"
        },
        earningsP_: {
            description: "Earnings comparison",
            format: "PERCENT"
        },
        earnings: {
            description: "Earnings Total",
            format: "MONEY"
        },
        earningsP: {
            description: "Earnings (%)",
            format: "PERCENT"
        },
        assetsValue: {
            description: "Assets Value",
            format: "MONEY"
        },
        totalDeposits_: {
            description: "Total Deposits",
            format: "MONEY"
        },
        totalDeposits: {
            description: "Total Deposits",
            format: "MONEY"
        },
        cashAvailableToInvest: {
            description: "Cash Available to Invest",
            format: "MONEY"
        },
        cashAvailableForWithdrawal: {
            description: "Cash Available for Withdrawal",
            format: "MONEY"
        },
        assetsSoldAndReinvest_: {
            description: "Assets Sold and Reinvested",
            format: "MONEY"
        },
        sellProfit_: {
            description: "Sell Profit",
            format: "MONEY"
        },
        totalFees_: {
            description: "Total fees",
            format: "MONEY"
        },
        totalTaxes_: {
            description: "Total taxes",
            format: "MONEY"
        },
        sellProfitAfterTaxesAndFees_: {
            description: "Sell profit after taxes and fees",
            format: "MONEY"
        },

        assetsSoldAndReinvest: {
            description: "Assets Sold and Reinvested",
            format: "MONEY"
        },
        sellProfit: {
            description: "Sell Profit",
            format: "MONEY"
        },
        totalFees: {
            description: "Total fees",
            format: "MONEY"
        },
        totalTaxes: {
            description: "Total taxes (Scalable)",
            format: "MONEY"
        },
        sellProfitAfterTaxesAndFees: {
            description: "Sell profit after taxes and fees (Scalable)",
            format: "MONEY"
        },
        totalTaxes2665: {
            description: "Total taxes (Mario)",
            format: "MONEY"
        },
        sellProfitAfterTaxesAndFees2665: {
            description: "Sell profit after taxes and fees (Mario)",
            format: "MONEY"
        },
        timestamp: {
            description: "Data last update",
            format: "TIMESTAMP"
        },        
    }

};